import { useEffect } from "react";
import { useRouter } from "next/router";

import FullScreenLoader from "@/components/Loaders/FullScreenLoader/Loader";
import { Auth } from "@/components/Auth/Auth";
import Brand from "@/components/Typography/Brand/Brand";
import { isEmptyObject } from "@/utils/object";
import { useAuth } from "@/context/AuthContext";
import { useAnalytics } from "@/context/GoogleAnalyticsContext";
import styles from "@/styles/pages/Home.module.scss";

const Home = () => {
  const router = useRouter();
  const { authLoading, user } = useAuth();
  const { sendAnalyticsRecord } = useAnalytics();

  useEffect(() => {
    if (!isEmptyObject(user)) {
      router.push("/generator");
    }
  }, [router, user]);

  useEffect(() => {
    sendAnalyticsRecord("page_view", {
      page_location: window.location.href,
      page_title: document?.title,
      page_name: "Home",
    });
  }, []);

  return authLoading ? (
    <FullScreenLoader isLoading={authLoading} />
  ) : (
    <div className={styles.homeContent}>
      <div className={styles.overlay}>
        <div className={styles.leftContainer}></div>

        <div className={styles.rightContainer}>
          <Brand variant="large" />
          <div className={styles.authContainer}>
            <Auth />
          </div>
        </div>
      </div>
      <div className={styles.splashContainer}></div>
    </div>
  );
};

export default Home;
